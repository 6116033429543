import { Link, graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import PortableText from "react-portable-text";
import Container from "../components/Container/Container";
import MutedButton from "../components/Global/MutedButton";
import InView from "../components/InView";
import Seo from "../components/SEO/SEO";
import GraphQLErrorList from "../components/graphql-error-list";
import { mapEdgesToNodes } from "../lib/helpers";

export const query = graphql`
	query TeamPageQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			defaultImage {
				asset {
					_id
				}
			}
		}
		sanityOurTeam {
			title
			introduction
			hero {
				kind
				heroImage {
					...Image
					alt
				}
				heroVideoUrl
			}
			featureTeam {
				id
			}
			content {
				... on SanityFullBleedImage {
					_key
					_type
					image {
						...Image
						alt
						asset {
							_id
						}
					}
				}
				... on SanityImageTextBlock {
					_key
					_type
					description
					imageDirection
					imageType
					layout
					title
					link
					linkText
					media
					videoUrl
					image {
						...Image
						alt
						asset {
							_id
						}
					}
				}
			}
		}
		people: allSanityPerson(sort: { fields: [order], order: ASC }) {
			edges {
				node {
					id
					order
					previewImage {
						...Image
						alt
						asset {
							_id
						}
					}
					mainImage {
						...Image
						alt
						asset {
							_id
						}
					}
					title
					role
					slug {
						current
					}
					_rawBody
				}
			}
		}
	}
`;

const OurTeam = (props) => {
	const { data, errors } = props;
	const site = (data || {}).site;

	const [activePerson, setActivePerson] = useState(null);
	const [hidePerson, setHidePerson] = useState(null);
	const [muted, setMuted] = useState(true);
	const [loaded, setLoaded] = useState(false);
	const [otherPeopleNodes, setotherPeopleNodes] = useState(null);

	const shuffle = (array) => {
		let counter = array.length;

		// While there are elements in the array
		while (counter > 0) {
			// Pick a random index
			let index = Math.floor(Math.random() * counter);

			// Decrease counter by 1
			counter--;

			// And swap the last element with it
			let temp = array[counter];
			array[counter] = array[index];
			array[index] = temp;
		}
		return array;
	};

	useEffect(() => {
		const slug = props.path.split("/")[2];
		if (slug) {
			const person = data.people.edges.find(
				(el) => el.node.slug.current === slug
			).node;
			if (person) {
				setActivePerson(person);

				var element = document.getElementById(person.id);
				var headerOffset = 60;
				var elementPosition = element.getBoundingClientRect().top;
				var offsetPosition =
					elementPosition - headerOffset + window.scrollY - 180;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});

				setTimeout(() => {
					document.body.style.overflow = "hidden";
				}, 300);
			} else {
				document.body.style.overflow = "scroll";
			}
		}
		const otherNodes = (data || {}).people
			? mapEdgesToNodes(data.people).filter(
					(people) => !ids.includes(people.id)
			  )
			: [];
		setotherPeopleNodes(shuffle(otherNodes));
	}, []);

	if (errors) {
		return (
			<>
				<GraphQLErrorList errors={errors} />
			</>
		);
	}

	const ids = data.sanityOurTeam.featureTeam.map((team) => team.id);
	const peopleNodes = (data || {}).people
		? mapEdgesToNodes(data.people).filter((people) => ids.includes(people.id))
		: [];

	return (
		<>
			<Seo
				title={data.sanityOurTeam.title}
				description={data.sanityOurTeam.description}
				image={
					data.sanityOurTeam.hero.heroImage
						? data.sanityOurTeam.hero.heroImage
						: site.defaultImage
				}
			/>
			<Container>
				<div className={`${activePerson && `show-person`}`}>
					<div className="absolute hidden md:inline-block transform left-1/4 -translate-x-1/4 xl:left-1/2 xl:-translate-x-1/2 container top-20 z-10 text-white">
						<h1 className="font-serif text-[32px] leading-[1.2]">
							{data.sanityOurTeam.title}
						</h1>
					</div>

					{data.sanityOurTeam.hero.kind === "video" && (
						<div
							className={
								`hero-wrapper mb-4 md:mb-0 video-wrapper lazy ` +
								(loaded && " loaded")
							}
						>
							<ReactPlayer
								width="100vw"
								height="100vh"
								playing
								playsinline={true}
								loop
								muted
								url={data.sanityOurTeam.hero.heroVideoUrl}
								onStart={() => setLoaded(true)}
							/>
						</div>
					)}

					{data.sanityOurTeam.hero.kind === "image" && (
						<div className="hero-wrapper mb-4 md:mb-0">
							<Image
								{...data.sanityOurTeam.hero.heroImage}
								width={1600}
								height={880}
								alt={data.sanityOurTeam.hero.heroImage.alt}
								className={`lazy ` + (loaded && " loaded")}
								onLoad={() => setLoaded(true)}
							/>
						</div>
					)}

					<InView>
						<div className="border-b border-grey-default box-border relative">
							<div className="container relative flex flex-wrap flex-col md:flex-row md:mt-[120px] flex-start md:h-full md:min-h-screen">
								<div className="md:w-1/3 md:sticky md:h-full md:top-[81px] md:pb-[25px] md:pr-10">
									{data.sanityOurTeam.introduction.length > 0 &&
										data.sanityOurTeam.introduction && (
											<h2 className="font-serif text-lg max-w-[520px]">
												{data.sanityOurTeam.introduction}
											</h2>
										)}
								</div>
								<div className="md:w-2/3 mt-16 mb-10 md:my-0 md:pl-10 md:border-l md:border-grey-default md:box-border">
									<div className="grid grid-cols-12 gap-6 md:gap-10 md:pb-10 md:h-full">
										{peopleNodes && (
											<PersonListing
												nodes={peopleNodes}
												setActivePerson={setActivePerson}
												setHidePerson={setHidePerson}
												activePerson={activePerson}
											/>
										)}
										{otherPeopleNodes && (
											<PersonListing
												nodes={otherPeopleNodes}
												setActivePerson={setActivePerson}
												setHidePerson={setHidePerson}
												activePerson={activePerson}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</InView>

					{data.sanityOurTeam.content.length > 0 &&
						data.sanityOurTeam.content.map((content, i) => (
							<div key={i}>
								{content._type === "imageTextBlock" && (
									<InView>
										<div className="border-b border-grey-default box-border">
											<div className="container flex flex-col md:grid md:grid-cols-12 py-7 md:py-0 md:mt-10 gap-6 md:gap-10">
												<div
													className={`flex flex-col md:pb-10 ${
														content.layout === "video" ? ` md:col-span-4` : ` `
													} ${
														content.layout === "two-thirds"
															? ` md:col-span-5`
															: ` `
													} ${
														content.layout === "one-quarter"
															? ` md:col-span-8`
															: ` `
													}${
														content.imageDirection === "image-right"
															? ` md:pr-10 order-1`
															: ` order-2 mt-4 md:mt-0`
													}`}
												>
													<h3 className="max-w-[350px]">{content.title}</h3>
													<div
														className={`mt-3 md:mt-auto ${
															content.layout === "two-thirds"
																? ` max-w-[520px]`
																: ` max-w-[680px]`
														}`}
													>
														<h2 className="font-serif text-lg mb-2">
															{content.description}
														</h2>
														{content.link && (
															<Link
																className="text-sm text-grey-base link"
																to={content.link}
															>
																{content.linkText}
															</Link>
														)}
													</div>
												</div>
												<div
													className={`md:pb-10 md:border-grey-default box-border ${
														content.layout === "video" ? ` md:col-span-8` : ` `
													} ${
														content.layout === "two-thirds"
															? ` md:col-span-7`
															: ` `
													} ${
														content.layout === "one-quarter"
															? ` md:col-span-4`
															: ` `
													} ${
														content.imageDirection === "image-right"
															? ` order-2 md:pl-10 md:border-l mt-10 md:mt-0`
															: ` order-1 md:pr-10 md:border-r`
													}`}
												>
													{content.media === "image" &&
														content.imageType === "landscape" && (
															<Image
																{...content.image}
																width={1000}
																height={680}
																alt={content.image.alt}
																className={`lazy ` + (loaded && " loaded")}
																onLoad={() => setLoaded(true)}
															/>
														)}
													{content.media === "image" &&
														content.imageType === "portrait" && (
															<Image
																{...content.image}
																width={605}
																height={880}
																alt={content.image.alt}
																className={
																	`max-w-[75%] md:max-w-[90%] max-h-[880px] w-auto lazy ` +
																	(loaded && " loaded")
																}
																onLoad={() => setLoaded(true)}
															/>
														)}
													{content.media === "video" && (
														<div
															className={
																`video-wrapper relative lazy ` +
																(loaded && " loaded")
															}
														>
															<ReactPlayer
																className="react-player"
																width="100%"
																height="100%"
																playing
																playsinline={true}
																loop
																muted={muted}
																config={{
																	file: {
																		attributes: {
																			autoPlay: true,
																			muted: true,
																		},
																	},
																}}
																url={content.videoUrl}
																onReady={() => setLoaded(true)}
															/>
															<MutedButton muted={muted} setMuted={setMuted} />
														</div>
													)}
												</div>
											</div>
										</div>
									</InView>
								)}

								{content._type === "fullBleedImage" && (
									<InView key={i}>
										<Image
											{...content.image}
											width={1400}
											height={770}
											alt={content.image.alt}
											className={`lazy ` + (loaded && " loaded")}
											onLoad={() => setLoaded(true)}
										/>
									</InView>
								)}
							</div>
						))}

					{activePerson && (
						<>
							<div className={`person ${hidePerson ? "" : "show"}`}>
								<div className="person--content relative max-w-[516px]">
									<h2 className="font-serif text-lg">{activePerson.title}</h2>
									<h4 className="text-sm mt-1">{activePerson.role}</h4>

									{activePerson.mainImage.asset && <Image
										{...activePerson.mainImage}
										width={500}
										height={752}
										alt={activePerson.mainImage.alt}
										className={`mt-8 md:mt-[20%] lazy ` + (loaded && " loaded")}
										onLoad={() => setLoaded(true)}
									/>}
									<div className="copy copy--container mt-6">
										{activePerson._rawBody && (
											<PortableText
												content={activePerson._rawBody}
												serializers={{
													internalLink: ({ reference, children }) => {
														const href = `/${reference._type}/${reference.slug.current}`;
														return <Link to={href}>{children}</Link>;
													},
													link: ({ href, blank, children }) => {
														return (
															<a
																href={href}
																target={blank ? "_blank" : "_self"}
																rel={blank ? "noreferrer" : undefined}
															>
																{children}
															</a>
														);
													},
												}}
											/>
										)}
									</div>
								</div>
								<button
									className="absolute top-5 md:top-8 right-5 md:right-8"
									onClick={() => {
										document.body.style.overflow = "scroll";
										window.history.pushState({}, "", "/our-team");
										setHidePerson(true);
										setTimeout(() => {
											setActivePerson(null);
										}, 400);
									}}
								>
									<span className="sr-only">Close</span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										fill="none"
										viewBox="0 0 20 20"
									>
										<path
											stroke="#000"
											d="M1.188 1.146l18 18m-18.708 0l18-18"
										></path>
									</svg>
								</button>
							</div>
							<div
								className={`person--overlay ${hidePerson ? "" : "show"}`}
								role="button"
								tabIndex="0"
								onClick={() => {
									document.body.style.overflow = "scroll";
									window.history.pushState({}, "", "/our-team");
									setHidePerson(true);
									setTimeout(() => {
										setActivePerson(null);
									}, 400);
								}}
								aria-hidden="true"
							></div>
						</>
					)}
				</div>
			</Container>
		</>
	);
};

function PersonListing(props) {
	return (
		<>
			{props.nodes &&
				props.nodes.map((node) => (
					<div className="col-span-6 xl:col-span-4 lg:pr-10" key={node.id}>
						<PersonPreview
							person={node}
							setActivePerson={props.setActivePerson}
							setHidePerson={props.setHidePerson}
						/>
					</div>
				))}
		</>
	);
}

PersonListing.defaultProps = {
	title: "",
	nodes: [],
};

function PersonPreview(props) {
	const [loaded, setLoaded] = useState(false);
	const { person } = props;

	const handleClickPerson = () => {
		var element = document.getElementById(person.id);
		var headerOffset = 88;
		var elementPosition = element.getBoundingClientRect().top;
		var offsetPosition = elementPosition - headerOffset + window.scrollY;

		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth",
		});

		props.setActivePerson(person);
		props.setHidePerson(false);

		setTimeout(() => {
			document.body.style.overflow = "hidden";
		}, 1000);

		if (window) {
			window.history.pushState({}, "", `/our-team/${person?.slug.current}`);
		}
	};

	var mainImage = person.mainImage && person.mainImage.asset;

	const cancelClick = () => {
		console.log("onclick..");
	};

	return (
		<button
			className={`text-left ${mainImage ? "person-show" : "person-dont-show"}`}
			onClick={mainImage ? handleClickPerson : cancelClick}
			id={person.id}
		>
			{person.previewImage && (
				<>
					<div className="overflow-hidden relative image-wrapper">
						<Image
							{...person.previewImage}
							width={500}
							height={752}
							alt={person.previewImage.alt || ""}
							className={`lazy ` + (loaded && " loaded")}
							onLoad={() => setLoaded(true)}
						/>
						<span className="w-[40px] h-[40px] bg-white absolute bottom-0 right-0 z-10 flex items-center justify-center md:hidden">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19">
								<path
									fill="none"
									fillRule="evenodd"
									stroke="#000"
									strokeLinecap="square"
									strokeWidth="2"
									d="M18.5 9.5h-17M10 1v17"
								></path>
							</svg>
						</span>
					</div>
					<h2 className="font-serif text-lg mt-4 mb-1">{person.title}</h2>
					<h4 className="text-sm">{person.role}</h4>
				</>
			)}
		</button>
	);
}

export default OurTeam;
